import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Text = styled.span`
  ${props =>
    props.justified &&
    `
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `}
`

const RANDOM_MIN_INTERVAL = 2000
const RANDOM_MAX_INTERVAL = 20000

class RollingText extends PureComponent {
  static propTypes = {
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    letterDelay: PropTypes.number.isRequired,
    animate: PropTypes.bool,
    className: PropTypes.string,
    numbersOnly: PropTypes.bool,
    randomAnimate: PropTypes.bool,
    randomMinInterval: PropTypes.number,
    randomMaxInterval: PropTypes.number,
    justified: PropTypes.bool,
  }

  static defaultProps = {
    numbersOnly: false,
    letterDelay: 100,
    randomMinInterval: RANDOM_MIN_INTERVAL,
    randomMaxInterval: RANDOM_MAX_INTERVAL,
  }

  constructor(props) {
    super(props)

    this.state = {
      currentText: props.text,
      targetText: props.text,
    }

    this.animateCount = 0
    this.timeout = null
    this.initRandomAnimate = this.initRandomAnimate.bind(this)
    this.animateText = this.animateText.bind(this)
    this.getUpdatedText = this.getUpdatedText.bind(this)
    this.renderText = this.renderText.bind(this)
  }

  componentDidMount() {
    this.animateText()
  }

  componentWillUnmount() {
    window.clearTimeout(this.timeout)
  }

  componentDidUpdate(prevProps) {
    if (this.props.animate !== prevProps.animate) {
      this.animateText()
    } else if (
      this.props.text !== this.state.targetText &&
      this.state.currentText != this.state.targetText
    ) {
      this.animateCount = 0
      this.setState(
        {
          targetText: this.props.text,
        },
        this.animateText()
      )
    }
  }

  initRandomAnimate() {
    const { randomMinInterval, randomMaxInterval } = this.props
    this.animateCount = 0
    const nextAnimation =
      Math.floor(Math.random() * randomMaxInterval) + randomMinInterval
    this.timeout = window.setTimeout(() => {
      this.animateText()
    }, nextAnimation)
  }

  getUpdatedText() {
    const { currentText, targetText } = this.state
    let newText = ''

    if (currentText && targetText) {
      let chars = 'abcdefghijklmnopqrstuvxyz'
      if (this.props.numbersOnly) {
        chars = '0123456789'
      }

      for (var i = 0; i < currentText.length; i++) {
        if (currentText.charAt(i) === ' ') {
          newText += ' '
        } else {
          const randomChar = chars.charAt(
            Math.floor(Math.random() * chars.length)
          )
          newText += randomChar
        }
      }

      if (newText.length > targetText.length) {
        newText = newText.substring(0, newText.length - 2)
      } else if (newText.length < targetText.length) {
        const randomChar = chars.charAt(
          Math.floor(Math.random() * chars.length)
        )
        newText += randomChar
      }
    }
    return newText
  }

  animateText() {
    const { letterDelay, animate, randomAnimate } = this.props
    const { currentText, targetText } = this.state

    if (animate) {
      const newText = this.getUpdatedText()

      this.setState({ currentText: newText }, () => {
        this.timeout = window.setTimeout(() => {
          this.animateText()
        }, letterDelay)
      })
    } else if (targetText !== currentText && this.animateCount < 10) {
      const newText = this.getUpdatedText()

      this.setState({ currentText: newText }, () => {
        this.timeout = window.setTimeout(() => {
          this.animateCount++
          this.animateText()
        }, letterDelay)
      })
    } else if (randomAnimate && this.animateCount < 10) {
      const newText = this.getUpdatedText()

      this.setState({ currentText: newText }, () => {
        this.timeout = window.setTimeout(() => {
          this.animateCount++
          this.animateText()
        }, letterDelay)
      })
    } else if (randomAnimate) {
      this.setState(
        {
          currentText: targetText,
        },
        () => {
          this.initRandomAnimate()
        }
      )
    } else {
      this.setState({
        currentText: targetText,
      })
    }
  }

  renderText() {
    const { justified } = this.props
    const { currentText } = this.state

    if (justified) {
      const split_text = currentText.split(' ')

      if (split_text.length > 1) {
        return split_text.map((word, index) => <span key={index}>{word}</span>)
      }

      return currentText
        .split('')
        .map((letter, index) => <span key={index}>{letter}</span>)
    } else {
      return <span dangerouslySetInnerHTML={{ __html: currentText }} />
    }
  }

  render() {
    const { className, justified } = this.props
    return (
      <Text justified={justified} className={className}>
        {this.renderText()}
      </Text>
    )
  }
}

export default RollingText
